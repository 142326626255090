
import { defineComponent } from "vue";
import Unsupported from "@/blueprint/views/Unsupported.vue";
import CButton from "@/blueprint/components/Button.vue";

export default defineComponent({
  name: "App",
  components: {
    Unsupported,
    "c-button": CButton
  },
  data: () => ({
    updateApplicationLoading: false
  }),
  methods: {
    updateApplication() {
      if (navigator && navigator.serviceWorker) {
        this.updateApplicationLoading = true;
        navigator.serviceWorker
          .getRegistrations()
          .then(function(registrations) {
            // Clear installed service workers
            for (const registration of registrations) {
              registration.unregister();
            }
            // Reload current window
            location.reload();
          });
      }
    }
  }
});
