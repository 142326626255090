import { render } from "./Button.vue?vue&type=template&id=58885f62"
import script from "./Button.vue?vue&type=script&lang=ts"
export * from "./Button.vue?vue&type=script&lang=ts"

import "./Button.vue?vue&type=style&index=0&id=58885f62&lang=stylus"
script.render = render

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn});
