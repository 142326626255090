<!-- Component HTML -->
<template>
  <q-btn
    flat
    round
    :icon="icon"
    size="md"
    :color="color"
    :loading="loading"
  ></q-btn>
</template>

<!-- Component Script -->
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "C-Button",
  props: {
    icon: {
      type: String,
      required: true,
      default: null
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: String,
      required: false,
      default: "black"
    }
  }
});
</script>

<!-- Component CSS -->
<style lang="stylus">
// Default - Mobile Viewport

// Tablet Viewport
@media screen and (min-width: 599px) {}

// Desktop Viewport
@media screen and (min-width: 1022px) {}
</style>
