<!-- Component HTML -->
<template>
  <div class="unsupported">
    <p>This PWA is not supported in Tablet or Desktop viewports</p>
  </div>
</template>

<!-- Component Script -->
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Unsupported"
});
</script>

<!-- Component CSS -->
<style lang="stylus">
// Default - Mobile Viewport
.unsupported
  position: fixed
  height: 100vh
  width: 100vw
  top: 0;
  left: 0
  z-index: 100;
  background-color: #fff
  display: none

// Tablet Viewport
@media screen and (min-width: 599px)
  .unsupported
    display: flex
    align-items: center
    justify-content: center


// Desktop Viewport
@media screen and (min-width: 1022px) {}
</style>
