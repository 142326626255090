<!-- Component HTML -->
<template>
  <div id="crypto-dashboard">
    <!-- App Header -->
    <header class="app-header">
      <!-- Update App -->
      <c-button
        icon="update"
        color="white"
        :loading="updateApplicationLoading"
        @click="updateApplication()"
      ></c-button>
    </header>
    <!-- App Body -->
    <div class="app-body">
      <router-view></router-view>
    </div>
    <!-- Unsupported Viewport-->
    <unsupported></unsupported>
  </div>
</template>

<!-- Component Script -->
<script lang="ts">
import { defineComponent } from "vue";
import Unsupported from "@/blueprint/views/Unsupported.vue";
import CButton from "@/blueprint/components/Button.vue";

export default defineComponent({
  name: "App",
  components: {
    Unsupported,
    "c-button": CButton
  },
  data: () => ({
    updateApplicationLoading: false
  }),
  methods: {
    updateApplication() {
      if (navigator && navigator.serviceWorker) {
        this.updateApplicationLoading = true;
        navigator.serviceWorker
          .getRegistrations()
          .then(function(registrations) {
            // Clear installed service workers
            for (const registration of registrations) {
              registration.unregister();
            }
            // Reload current window
            location.reload();
          });
      }
    }
  }
});
</script>

<!-- Component CSS -->
<style lang="stylus">
// Default - Mobile Viewport
#crypto-dashboard
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  color #2c3e50
  position: relative

.app-header
  height: 4rem
  padding: 0 0.5rem
  background-color: #000
  display: flex
  justify-content: flex-end
  align-items: center

.app-body
  height: calc(100vh - 4rem)
  width: 100vw
  overflow: auto

body
  margin: 0

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

// Tablet Viewport
@media screen and (min-width: 599px) {}

// Desktop Viewport
@media screen and (min-width: 1022px) {}
</style>
