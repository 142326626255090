import "quasar/dist/quasar.css";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/mdi-v4/mdi-v4.css";

import QBtn from 'quasar/src/components/btn/QBtn.js';import QCard from 'quasar/src/components/card/QCard.js';import QCardActions from 'quasar/src/components/card/QCardActions.js';import QCardSection from 'quasar/src/components/card/QCardSection.js';import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';import QIcon from 'quasar/src/components/icon/QIcon.js';import QItem from 'quasar/src/components/item/QItem.js';import QItemLabel from 'quasar/src/components/item/QItemLabel.js';import QItemSection from 'quasar/src/components/item/QItemSection.js';import QList from 'quasar/src/components/item/QList.js';import QSeparator from 'quasar/src/components/separator/QSeparator.js';import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';import QTab from 'quasar/src/components/tabs/QTab.js';import QTabs from 'quasar/src/components/tabs/QTabs.js';;

import iconSet from "quasar/icon-set/mdi-v4.js";

// To be used on app.use(Quasar, { ... })
export default {
  components: {
    QBtn,
    QCard,
    QCardSection,
    QCardActions,
    QSeparator,
    QIcon,
    QCircularProgress,
    QTab,
    QTabs,
    QList,
    QItem,
    QItemLabel,
    QItemSection,
    QSkeleton
  },
  config: {},
  plugins: {},
  iconSet: iconSet
};
