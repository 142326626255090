
import { defineComponent } from "vue";

export default defineComponent({
  name: "C-Button",
  props: {
    icon: {
      type: String,
      required: true,
      default: null
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: String,
      required: false,
      default: "black"
    }
  }
});
